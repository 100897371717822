'use client';

import { useRef, useState } from 'react';
import Link from 'next/link';
import { AnimatePresence, motion } from 'framer-motion';

export function NavLinks({footer = false}: {footer?: boolean})
{
	let [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
	let timeoutRef = useRef<number | null>(null);

  const links = [
		['Our Research', '/research'],
		['How We Help', '/#features'],
		['What We Offer', '/#secondary-features'],
		['Pricing', '/pricing']
		//['Request Trial', 'mailto:amurthy@mavenbio.io'],
		//['Get a Demo', 'https://cal.com/team/maven-bio/intro']
	]

  if(footer)
  {
    links.push(['Privacy Policy', '/privacy']);
    links.push(['Terms of Service', '/terms']);
	links.push(['Changelog', '/changelog']);
  }

	return links.map(([label, href], index) => (
		<Link
			key={label}
			href={href}
			className='relative -mx-3 -my-2 rounded-lg px-3 py-2 text-base text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-0'
			onMouseEnter={() =>
			{
				if (timeoutRef.current)
				{
					window.clearTimeout(timeoutRef.current);
				}
				setHoveredIndex(index);
			}}
			onMouseLeave={() =>
			{
				timeoutRef.current = window.setTimeout(() =>
				{
					setHoveredIndex(null);
				}, 200);
			}}
		>
			<AnimatePresence>
				{hoveredIndex === index && (
					<motion.span
						className='absolute inset-0 rounded-lg bg-gray-100'
						layoutId='hoverBackground'
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { duration: 0.15 } }}
						exit={{
							opacity: 0,
							transition: { duration: 0.15 }
						}}
					/>
				)}
			</AnimatePresence>
			<span className='relative z-10'>{label}</span>
		</Link>
	));
}
